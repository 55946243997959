$base-font-size: 12px;
$title-font-size: 1.3em;

$primary-color-dark: #f4c400;
$primary-color: #f4c400;
$primary-color-light: #f4c400;
$primary-color-text: #ffffff;
$accent-color: #f4c400;
$primary-text-color: #212121;
$secondary-text-color: #757575;
$divider-color: #e4e4e4;
$today-bg-color: #fff9c4;

@import "node_modules/@y0c/react-datepicker/assets/styles/_mixin.scss";
@import "node_modules/@y0c/react-datepicker/assets/styles/app.scss";
