/* ===================================================================
*
*  PROGRAMA DE LEALTAD CARGILL
*  APRIL 12, 2021
*  PAGE NOT FOUND (404)
*
* ====================================================================
01. 404
* ==================================================================== */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&display=swap');

* {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

body {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100vh;
    padding: 0;
    margin: 0;

    color: white;
    font-size: 1rem;
    letter-spacing: 0.02rem;
    font-family: 'Montserrat', sans-serif;
    background-color: black;
}

a {
    text-decoration: none;
}

.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.heading h1 {
    color: #ffec03;
    font-size: 5rem;
    font-weight: 700;
    padding: 0;
    margin: 0;
}

.description h2 {
    color: #ffec03;
    font-size: 1.5rem;
    font-weight: 700;

    padding: 0;
    margin: 0;
}

.description p {
    font-size: 1rem;
    line-height: 1.5;

    padding: 1.5rem 1.5rem;
    margin: 0;
}

.description p  br {
    display: none;
}

.btn {
    position: relative;
    padding: 0.5rem 2rem;
    border-radius: 25rem;
    cursor: pointer;

    font-size: 1.1rem;
    letter-spacing: 0.01rem;
    font-weight: 500;

    vertical-align: middle;
    display: inline-block;
    text-align: center;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    color: inherit;
    background-color: transparent;
    border: 2px solid transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.btn-primary {
    background-color:#ffec03;
    border-color: #ffec03;
    color: black;
}

.btn-primary:hover {
    background-color: #c7ba00;
    border-color: #c7ba00;
    color: black;
}

.btn-primary:focus, 
.btn-primary.focus {
    background-color:#ffec03;
    border-color: #ffec03;
    color: black;

    box-shadow: 0 0 0 0.2rem rgba(255, 236, 3, 0.5);
}
  
.btn-primary.disabled, 
.btn-primary:disabled {
    opacity: 0.5;
}

.btn-primary:not(:disabled):not(.disabled):active, 
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    background-color:#ffec03;
    border-color: #ffec03;
    color: black;
}
 
.btn-primary:not(:disabled):not(.disabled):active:focus, 
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 236, 3, 0.5);
}

@media only screen and (min-width: 768px) {
    
    .heading h1 {
        font-size: 8rem;
        font-weight: 700;
    }

    .description h2 {
        font-size: 2rem;
        font-weight: 700;
    }

    .description p {
        font-size: 1.1rem;
    }

    .description p  br {
        display: block;
    }
}