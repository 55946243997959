.picker,
.picker-input {
  width: 100%;
}

.calendar__day, .calendar__month, .calendar__year  {
  color: black;
}

.swal2-x-mark-line-left,
.swal2-x-mark-line-right,
.swal2-success-line-long,
.swal2-success-line-tip {
  font-size: 1rem;
}

.swal2-popup {
  background-color: #f4c400;
}

.swal2-content,
.swal2-icon.swal2-success,
.swal2-icon.swal2-info {
  color: black;
}

.swal2-icon.swal2-error,
.swal2-icon.swal2-success,
.swal2-icon.swal2-success .swal2-success-ring,
.swal2-icon.swal2-info {
  border-color: black;
}

.swal2-styled.swal2-confirm,
.swal2-icon.swal2-error [class^="swal2-x-mark-line"],
.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: black;
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.text-danger {
  color: #f4c400;
}
